let declOfNum = function (number, titles = ['год', 'года', 'лет']) {
    let cases = [2, 0, 1, 1, 1, 2];
    return number + ' ' + titles[(number % 100 > 4 && number % 100 < 20) ? 2 : cases[(number % 10 < 5) ? number % 10 : 5]];
}

class Calculator {


    constructor() {
        this.$calc = $('.credit-calc');
        this.$calcResult = this.$calc.find('.credit-calc__result');
        this.$banks = this.$calc.find('.credit-calc__banks');
        this.$calcBtn = this.$calc.find('.credit-calc__btn');

        this.$cost = this.$calc.find('input[name="cost"]');

        this.$credit = this.$calc.find('input[name="credit"]');
        this.$firstPay = this.$calc.find('input[name="first-pay"]');
        this.$percent = this.$calc.find('input[name="percent"]');

		this.$yearSelect = this.$calc.find('.credit-calc__summary select')
        this.$resultBlock = $('.credit-calc__summary .credit-calc__block');

        // this.$calc.find('.form-control').formatter({
        //     'pattern': '{{999}} {{999}} {{999}} {{999}}',
        //     'persistent': true
        // });
        //
        this.$calcBtn.on('click', () => {
			this.calc();
        return false;
    });

        this.$cost.on('keyup', () => {
            this.creditSet();
    });

        this.$firstPay.on('keyup', () => {
            this.creditSet();
    });

        this.$percent.on('keyup', () => {
            this.creditSet();
    })

        this.firstCalc();


				
        this.$banks.find('.picker').on('click', () => {
            this.$percent.val(this.$banks.find('.picker.checked span').data('percent'));

        this.$yearSelect.html('');

        let years = this.$banks.find('.picker.checked span').data('years'),
            titles = ['год', 'года', 'лет'];

        if(years < 1) {
            titles = ['месяц', 'месяца', 'месяцев'];
            years = years * 12;
        }

		for (let i = 1; i < parseInt(years) + 1; i++) {
            this.$yearSelect.append('<option value="' + i + '">' + declOfNum(i, titles) + '</option>')
        }

        this.$yearSelect.selecter('refresh');
    });
    }

    firstCalc() {
        let firstPay = parseInt(this.$cost.val().replace(/\D/g, '')) * (this.$banks.find('.picker.checked span').data('firstPay') / 100);

        this.$firstPay.val(accounting.formatNumber(firstPay, 0, " ", ","));
        this.creditSet();
        this.calc();
    }


    creditSet() {
        let credit = parseInt(this.$cost.val().replace(/\D/g, '')) - parseInt(this.$firstPay.val().replace(/\D/g, ''));
		this.$credit.val(accounting.formatNumber(credit, 0, " ", ","));

        if (credit > 0) {
            this.$calcBtn.show();
            this.$credit.parent('.form-group').removeClass('has-error')
        } else {
            this.$calcBtn.hide();
            this.$credit.parent('.form-group').addClass('has-error')
        }
         //this.$cost.val(accounting.formatNumber(this.$cost.val(), 0, " ", ","))
         //this.$firstPay.val(accounting.formatNumber(this.$firstPay.val(), 0, " ", ","))
    }

    //
    // Считаем
    //
    calc() {

        // Забираем данные из формы
        let $currentBank = this.$banks.find('.picker.checked'),

        // bankPercent = $currentBank.find('span').data('percent'), // прцоентная ставка
            bankPercent = parseFloat(this.$percent.val()),
            bankYears = $currentBank.find('span').data('years'), // максимальный срок кредитования
            bankFirstPay = $currentBank.find('span').data('first-pay'), // минимальный первоначальный платеж
            
			cost = parseInt(this.$cost.val().replace(/\D/g, '')), // стоимость
            firstPay = parseInt(this.$firstPay.val().replace(/\D/g, '')), // первоначальный платеж
            credit = parseInt(this.$credit.val().replace(/\D/g, '')); // сумма кредита

        let year = parseFloat($('.credit-calc__summary .selecter-item.selected').data('value'))

        let result = [];

        // 1/12 часть годовой процентной ставки
        let monthPercent = (Math.round((bankPercent / 12) * 1000) / 1000) / 100,
            months = year * 12;

        let payment = 0,
            overpayment = 0;


        // Формируем строки на каждый год
        if(bankYears > 1) {
            this.$calcResult.removeClass('per-month');
            this.$calcResult.find('thead td:first-child').text('Срок кредита, год.')
            for (let i = 1; i < bankYears + 1; i++) {
                let months = i * 12, // период кредитования
                    payment = parseInt(credit * monthPercent / (1 - 1 / Math.pow(1 + monthPercent, months))) // Формула расчета ежемесячного платежа
                overpayment = parseInt(payment * months - credit); // переплата

                result.push({
                    year:        i,
                    overpayment: accounting.formatNumber(overpayment, 0, " ", ","),
                    payment:     accounting.formatNumber(payment, 0, " ", ",")
                })
            }

            payment = parseInt(credit * monthPercent / (1 - 1 / Math.pow(1 + monthPercent, months))), // Формула расчета ежемесячного платежа
                overpayment = parseInt(payment * months - credit); // переплата

        } else {
            this.$calcResult.addClass('per-month');
            //console.log(this.$calcResult.find('thead'));
            this.$calcResult.find('thead td:first-child').text('Срок кредита, мес.');
            for (let i = 1; i < (bankYears * 12) + 1; i++) {
                let payment = credit/i,
                    overpayment = 0;

                result.push({
                    year:        i,
                    overpayment: accounting.formatNumber(overpayment, 0, " ", ","),
                    payment:     accounting.formatNumber(payment, 0, " ", ",")
                })
            }

            payment = credit/months;
            overpayment = 0;
        }

        this.showResult(result);



        // Для мобильной версии
        result = {
            year:        year,
            overpayment: accounting.formatNumber(overpayment, 0, " ", ","),
            payment:     accounting.formatNumber(payment, 0, " ", ",")
        }


        this.$resultBlock.find('.form-group:first-child input').val(result.overpayment)
        this.$resultBlock.find('.form-group:last-child input').val(result.payment)
    }

    showResult(results) {

        let resultRows = '';

        results.forEach(function (result) {
            let row = "<tr>" +
                "<td>" + result.year + "</td>" +
                "<td>" + result.overpayment + "</td>" +
                "<td>" + result.payment + "</td>" +
                "</tr>"
            resultRows += row;
        });

        this.$calcResult.find('tbody').html(resultRows)
    }
}


$(document).ready(function () {
    if ($('.credit-calc').length) {
        new Calculator();
    }

});
